<template>
  <div>
    <EditDialog
      width="80%"
      :isReturn="true"
      @closeFn="$emit('update:history_show', false)"
      :isShow.sync="invoiceIsShow"
    >
      <div slot="title" class="title">历史开票记录</div>
      <div slot="content" class="content" max-width="400">
        <div v-if="currentInvoices" class="text">高亮行为当前单据</div>
        <el-table
          ref="multipleTable"
          :row-class-name="tableRowClassName"
          border
          row-key="id"
          :data="historyDataList"
        >
          <el-table-column type="expand" width="30">
            <template slot-scope="props">
              <el-table
                ref="tree"
                :data="props.row.invoiceApplicationDetailList"
                tooltip-effect="dark"
                size="mini"
                style="width: 96%; margin-bottom: 10px; margin-top: 10px; margin-left: 2.2%"
              >
                <el-table-column type="index" label="序号" min-width="70" align="center">
                  <template slot-scope="scope">
                    {{ props.$index + 1 }} .{{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="numberNo"
                  label="期数"
                  :show-overflow-tooltip="false"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope"> 第 {{ scope.row.numberNo }} 期 </template>
                </el-table-column> -->
                <el-table-column
                  prop="stageName"
                  label="阶段名称"
                  :show-overflow-tooltip="false"
                  min-width="200"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.stageName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="paymentAmount"
                  label="阶段金额"
                  min-width="110"
                  :show-overflow-tooltip="false"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.paymentAmount | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  :show-overflow-tooltip="false"
                  label="开票金额"
                  min-width="110"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.invoiceAmount | applyAmount }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceAmount"
                  :show-overflow-tooltip="false"
                  label="开票金额比例"
                  min-width="110"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.invoiceAmount && scope.row.paymentAmount">
                      {{
                        Number(
                          ((scope.row.invoiceAmount / scope.row.paymentAmount) * 100).toFixed(2)
                        )
                      }}
                      %</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="vatType"
                  :show-overflow-tooltip="false"
                  label="增值税类型"
                  min-width="220"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.vatType | transitionType(zzsList) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="VatData"
                  :show-overflow-tooltip="false"
                  label="开票内容"
                  min-width="220"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.vatData | transitionData(vatArr) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="vatRate"
                  :show-overflow-tooltip="false"
                  label="增值税税率"
                  min-width="110"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.vatRate"> {{ scope.row.vatRate }} %</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="taxesFee"
                  :show-overflow-tooltip="false"
                  min-width="110"
                  label="总税费"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.taxesFee | applyAmount }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" min-width="70" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="expenseNumber"
            :show-overflow-tooltip="false"
            label="单据编号"
            min-width="170"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createName"
            :show-overflow-tooltip="false"
            label="申请人"
            min-width="110"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="taskDate" min-width="110" label="申请时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.taskDate | dateFormat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sumCost"
            :show-overflow-tooltip="false"
            label="开票金额"
            min-width="110"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.sumCost | applyAmount }}
            </template>
          </el-table-column>

          <el-table-column
            prop="companyType"
            :show-overflow-tooltip="false"
            label="销售方"
            min-width="120"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.companyType | dict(companyType) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="customersUnit"
            :show-overflow-tooltip="false"
            label="购买方"
            min-width="120"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="statusName"
            :show-overflow-tooltip="false"
            label="当前状态"
            min-width="160"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="footer">
        <el-button type="info" plain @click="$emit('update:history_show', false)">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    history_show: {
      type: Boolean,
      default: false,
    },
    vatArr: {
      type: Array,
      default: function () {
        return []
      },
    },
    zzsList: {
      type: Array,
      default: function () {
        return []
      },
    },
    historyDataList: {
      type: Array,
      default: function () {
        return []
      },
    },
    companyType: {
      type: Array,
      default: function () {
        return []
      },
    },
    form: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      invoiceIsShow: false,
      currentInvoices: false,
    }
  },
  computed: {},
  watch: {
    history_show: {
      immediate: true,
      handler: function (val) {
        this.invoiceIsShow = val
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (row.id == this.form.id) {
        this.currentInvoices = true
        return ['tableRowClassName']
      }
      return []
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
    transitionType(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatType == val)
        str = item?.vatName
      }
      return str
    },
    transitionData(val, arr) {
      let str = ''
      if (arr && arr.length > 0 && val) {
        const item = arr.find(v => v.vatData == val)
        str = item?.vatName
      }
      return str
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.tableRowClassName {
  background-color: #ede88c;
}
.text {
  color: black;
  font-weight: 900;
  font-size: 18px;
}
</style>
